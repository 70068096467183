import React from "react";
import { useEmployeeProfile } from "../APIs/EmployeeAPI";
// import ImageComponent from "./ImageComponent";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import Logo from "../../../assets/images/footer-logo.png";
const EmployeeProfileView = ({ picstyle, profile, nav, w }) => {
  const { employeeProfile } = useEmployeeProfile();
  const profilePicture = profile ? profile : employeeProfile;
  if (!(profilePicture instanceof Blob)) {
    // If it's not a Blob, return null or display an error message
    return (
      <div className="position-relative">
        <img
        src={Logo}
        width={w} 
        style={{padding: '1%', borderRadius: "30%"}}
        className={`mit `}
        />
      </div>
    );
  }

  // Create URL for the Blob object
  const imageUrl = URL.createObjectURL(profilePicture);

  return (
    <div>
      {profilePicture ? (
        <img
          src={imageUrl}
          alt=""
          style={{
            ...picstyle,
          }}
        />
      ) : (
        <div className="position-relative">
          <AccountCircleIcon className="matrialuiicon_profile  mit rounded-circle sidebar_profile_placeholder" />
        </div>
      )}
    </div>
  );
};

export default EmployeeProfileView;
