import React from "react";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { Link } from "react-router-dom";
import { formatDistanceToNow } from "date-fns";
import { parseISO } from "date-fns";
import { format } from "date-fns";
function ExpiredProducts({ products, expiredDate, showEXPDates, showDates }) {
  // Get the current date
  const currentDate = new Date();
  let totalQuantity = 0;
  let totalPrice = 0;
  const expiredProducts = products.filter((product) => {
    const expirationDate = new Date(product.expiration_date);
    return expirationDate > currentDate
      ? expirationDate <= new Date(expiredDate)
      : expirationDate <= currentDate;
  });

  expiredProducts.forEach((product) => {
    totalQuantity += parseInt(product.product_quantity);
    totalPrice += parseFloat(product.product_total_price);
  });

  return (
    <div>
      <div className="container-fluid pt-4 px-4">
        <div className="row g-4 text-white">
          <div className="col-sm-6 col-xl-6">
            <div className="ovr-8 rounded d-flex align-items-center justify-content-between p-4">
              <i>
                <AccessTimeIcon className="newsalesicon" />
              </i>
              <div className="ms-3 text-end">
                <p className="mb-2">Total Expired Products QTY</p>
                <h6 className="mb-0">{totalQuantity}</h6>
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-xl-6">
            <div className="ovr-9 rounded d-flex align-items-center justify-content-between p-4">
              <i>
                <MonetizationOnIcon className="newsalesicon" />
              </i>
              <div className="ms-3 text-end">
                <p className="mb-2">Total Expired products Money</p>
                <h6 className="mb-0">{totalPrice.toFixed(2)}</h6>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Display Expired Products */}
      <div className="container-fluid pt-4 px-4 mb-5">
        <div className="secondarybg text-center rounded p-4">
          <div className="d-flex align-items-center justify-content-between mb-4">
            <h6 className="mb-0 text-primary">Recent Expired</h6>
            {/* Link to show all expired products */}
            <Link className="xpm">Show All</Link>
          </div>
          {/* Table to display expired products */}
          <div className="table-responsive">
            <table className="table text-start align-middle table-bordered table-hover table-dark table-striped">
              {" "}
              <thead>
                <tr className="text-white">
                  <th scope="col">Product ID</th>
                  <th scope="col">Product Name</th>
                  <th scope="col">Product Type</th>
                  <th scope="col">Quantity</th>
                  <th scope="col">Expiration Date</th>
                  <th scope="col">Added Date</th>
                </tr>
              </thead>
              <tbody>
                {expiredProducts.map((product, index) => (
                  <tr key={index}>
                    <td>{product.product_id}</td>
                    <td>{product.product_name}</td>
                    <td>{product.product_type}</td>
                    <td>{product.product_quantity}</td>
                    {showEXPDates ? (
                      <td>
                        <span>
                          {format(
                            parseISO(product.expiration_date),
                            "MM/dd/yyyy"
                          )}
                        </span>
                      </td>
                    ) : (
                      <td>
                        <span className="badge rounded-pill bg-primary">
                          {formatDistanceToNow(
                            parseISO(product.expiration_date),
                            { addSuffix: true }
                          )}
                        </span>
                      </td>
                    )}
                    {showDates ? (
                      <td>
                        <span className="sold_date-format">
                          {format(
                            parseISO(product.added_date),
                            "MM/dd/yyyy HH:mm:ss"
                          )}
                        </span>
                      </td>
                    ) : (
                      <td>
                        <span className="badge rounded-pill bg-light text-dark">
                          {formatDistanceToNow(parseISO(product.added_date), {
                            addSuffix: true,
                          })}
                        </span>
                      </td>
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ExpiredProducts;
