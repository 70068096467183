import React, { useState, useContext } from "react";

import agroSoldProductsService from "../../../../../services/agro_sold_product.service";
import {
  useAgroShowroomProducts,
  useAgroSoldProducts,
} from "../../../../components/APIs/FetchAPIs";
import { BarcodeScannerContext } from "../../../../../Context/BarcodeScannerContext";
import SoldProducts from "../../../../components/Products/SoldProducts";
function AgroSoldProducts() {
  const [successMessage, setSuccessMessage] = useState("");
  const [Error, setError] = useState(false);
  const { agroproducts } = useAgroShowroomProducts();
  const { isLoading } = useAgroSoldProducts();

  const { agrosoldProducts } = useContext(BarcodeScannerContext);
  const handleDeleteProduct = async (ProductId) => {
    if (window.confirm("Are you sure you want to delete this sold product?")) {
      try {
        const response = await agroSoldProductsService.deleteSoldAgroProduct(
          ProductId
        );
        if (response.status === 200) {
          const responseData = await response.json();

          setSuccessMessage(responseData.message);
        } else {
          throw new Error("Error: Product not found");
        }
      } catch (error) {
        setError("Error: " + error.message);
      }
    }
  };
  const handleEdit = async (event, ProductId, updatedProductData) => {
    event.preventDefault();
    try {
      const response = await agroSoldProductsService.updateSoldAgroProduct(
        ProductId,
        updatedProductData
      );
      if (response.status === "success") {
        setSuccessMessage("Product updated successfully.");
        setTimeout(() => {
          window.location.href = "/dashboard/showroom/agro/sold-products";
        }, 2000);
      } else {
        setError("Failed to update sold product. Please try again.");
      }
    } catch (error) {
      console.error("Error editing product:", error);
      setError("Error editing product. Please try again.");
    }
  };

  return (
    <SoldProducts
      handleDeleteProduct={handleDeleteProduct}
      soldProducts={agrosoldProducts}
      isLoading={isLoading}
      successMessage={successMessage}
      HandleEdit={handleEdit}
      products={agroproducts}
    />
  );
}

export default AgroSoldProducts;
