import React, { createContext, useState, useRef, useEffect } from "react";
import BarcodeReader from "react-barcode-reader";
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, TextField, Grid, Typography, Card, CardContent, Alert, Box, Snackbar, IconButton } from "@mui/material";
import errBeep from "../assets/Sounds/error.wav";
import warningBeep from "../assets/Sounds/alert.mp3";
import successBeep from "../assets/Sounds/beep-sound.mp3";

import ScannerService from "../services/barcode-scanne.service";
import {
  useAgroShowroomProducts,
  useAgroSoldProducts,
  useVetShowroomProducts,
  useVetSoldProducts,
} from "../markup/components/APIs/FetchAPIs";
import { useAuth } from "./AuthContext";

const BarcodeScannerContext = createContext();

const BarcodeScannerProvider = ({ children }) => {
  const [barcode, setBarcode] = useState("");
  const [service, setService] = useState("AGCHSR");
  const [error, setError] = useState("");
  const [id, setId] = useState("");
  const [productInfo, setProductInfo] = useState(null); 
  const [scanSuccess, setScanSuccess] = useState(false);
  const [snakMsg, setSnackMessage] = useState("");
  const [dialogOpen, setDialogOpen] = useState(false);
  const [productQty, setProductQty] = useState(null);
  const [isExpired, setIsExpired] = useState(false);
  const [isQtyMismatch, setIsQtyMismatch] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const errorBeepRef = useRef(null);
  const warningBeepRef = useRef(null);
  const successBeepRef = useRef(null);
  const qtyInputRef = useRef(null); 
  const { agrosoldProducts, aghandleFetchData } = useAgroSoldProducts();
  const { vetsoldProducts, handleFetchData} = useVetSoldProducts();
  const { agroproducts, isLoading, APIError, handleFetchData: AGROFETCH } = useAgroShowroomProducts();
  const { vetproducts , handleFetchData: VETFETCH } = useVetShowroomProducts();
  const [products, setProducts] = useState(null)

  const fetchData = service === "AGCHSR" ? AGROFETCH : service === "VTSR" ? VETFETCH : null;


useEffect(() => {
  if (service === "AGCHSR") {
    setProducts(agroproducts);
  } else if (service === "VTSR") {
    setProducts(vetproducts);
  }
}, [service, agroproducts, vetproducts]);


const { isLogged} = useAuth()


  const handleScan = (data) => {
    if (dialogOpen) {
      setDialogOpen(false);
      return;
    }
    if (data) {
      const Code = String(data)  
      const match = Code.match(/^([A-Z])-(.+)-(\d+)$/);
      if (!match) {
        errorBeepRef.current.play();
         setSnackMessage("Invalid Barcode!")
        setOpenSnackbar(true);
        return;
      }
      // const Branch = match[1];
      const Service = match[2];
      const idNumber = match[3];
      setService(Service)
      setId(idNumber);

     if(!isLogged){
      errorBeepRef.current.play();
      setSnackMessage("Access Denied!")
      setOpenSnackbar(true);
       return;
      }
      setBarcode(data);
      const matchedProduct = products.find((product) => Number(product.product_id) === Number(idNumber));

      if (matchedProduct) {
        checkExpiration(matchedProduct);
        setProductInfo(matchedProduct);
        setDialogOpen(true);
      } else {
        console.log("No product found with ID:", idNumber);
      }
    }
  };



  const handleSubmit = async () => {
    try {
    const data = {
      quantity: productQty,
      barcode: barcode,
    }
    console.log("I data", data) 
      const response = await ScannerService.Scan(data);
      
      if (response.status !== "true") {
        setScanSuccess(false);
        errorBeepRef.current.play();
      } else {
        successBeepRef.current.play();
        setScanSuccess(true);
      }

      if (response.data.service === "AGCHSR") {
        await aghandleFetchData();
        fetchData()
      } else {
        await handleFetchData();
        fetchData()

      }
      
      setTimeout(() => {
        setScanSuccess(false);
        setDialogOpen(false);
        setProductQty(null)
      }, 2000);

    } catch (err) {
      errorBeepRef.current.play();
      console.error(err);
    }
  };


  const handleDialogClose = () => {
    setDialogOpen(false);
  };
  const handleError = (err) => {
    setError(err);
  };

  const handleQtyChange = (e) => {
    const enteredQty = e.target.value;
    if (Number(enteredQty) > Number(productInfo.product_quantity)) {
      setIsQtyMismatch(true);
      errorBeepRef.current.play();
    } else {
      setIsQtyMismatch(false);
    }
    setProductQty(enteredQty);
  };
  
  useEffect(() => {
    const handleKeyPress = (e) => {
      if ( e.key === '/') {
        qtyInputRef.current.focus();
      }
    };
  
    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, []);

  

  const calculateTotalAmount = () => {
    if (productInfo && productQty && productInfo.product_unit_price) {
      return (productQty * parseFloat(productInfo.product_unit_price)).toFixed(2);
    }
    return 0.00;
  };
  const checkExpiration = (product) => {
    const currentDate = new Date();
    const expirationDate = new Date(product.expiration_date);
    if (expirationDate < currentDate) {
      setIsExpired(true);
      warningBeepRef.current.play();
    } else {
      setIsExpired(false);
    }
  };
  const handleKeyDown = (event) => {
    if (event.key === 'Insert' && !isQtyMismatch && !isExpired) {
      handleSubmit()
    }
  };

  return (
    <BarcodeScannerContext.Provider
      value={{ barcode, error, agrosoldProducts, vetsoldProducts, scanSuccess }}
    >
      {children}
      <BarcodeReader onError={handleError} onScan={handleScan} />
      <audio ref={errorBeepRef} src={errBeep} />
      <audio ref={warningBeepRef} src={warningBeep} />
      <audio ref={successBeepRef} src={successBeep} />

      <Snackbar
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={() => setOpenSnackbar(false)}
        message={snakMsg}
        severity="error"
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      />

      <Box component="form" 
        onKeyDown={handleKeyDown}>
      <Dialog open={dialogOpen} onClose={handleDialogClose} maxWidth={'lg'} PaperProps={{
    style: {
      backgroundColor: isExpired ? "#FFC1C3" : isQtyMismatch ? "#FFFFC5" : "white",
      boxShadow: 'none',
    }
  }}   >
        <DialogTitle>Scanned Item Information</DialogTitle>
        {isExpired && (
<Alert variant="filled" severity="error">
Please note, this product has expired and is no longer available for sale. If you need assistance, feel free to contact the Admin.
</Alert>
        )}
      {/* Quantity Mismatch Alert */}
      {isQtyMismatch && (
          <Alert variant="filled" severity="warning">
The quantity entered is not available in stock. Please confirm and try again.
</Alert>
        )}
        {scanSuccess && (
<Alert variant="filled" severity="success">
Product Sold Successfully!
</Alert>
        )}
                {!productInfo && (
<Alert variant="filled" severity="error">
Oops! We couldn't find any products. Try scanning another barcode!
</Alert>
        )}
    
        <DialogContent>
          {productInfo && (
            <Grid container spacing={2}>
              {/* Product Name */}
              <Grid item xs={6}>
                <Typography variant="h6">Product Name</Typography>
                <Typography>{productInfo.product_name}</Typography>
              </Grid>

              {/* Product Type */}
              <Grid item xs={6}>
                <Typography variant="h6">Product Type</Typography>
                <Typography>{productInfo.product_type}</Typography>
              </Grid>

              {/* Unit */}
              <Grid item xs={6}>
                <Typography variant="h6">Unit</Typography>
                <Typography>{productInfo.product_unit}</Typography>
              </Grid>

              {/* Quantity Available */}
              <Grid item xs={6}>
                <Typography variant="h6">Quantity Available</Typography>
                <Typography>{productInfo.product_quantity}</Typography>
              </Grid>

              {/* Unit Price */}
              <Grid item xs={6}>
                <Typography variant="h6">Unit Price</Typography>
                <Typography>ETB {productInfo.product_unit_price}</Typography>
              </Grid>

              {/* Total Price */}
              <Grid item xs={6}>
                <Typography variant="h6">Total Price</Typography>
                <Typography>ETB {productInfo.product_total_price}</Typography>
              </Grid>

              {/* Usage */}
              <Grid item xs={6}>
                <Typography variant="h6">Usage</Typography>
                <Typography>{productInfo.usage}</Typography>
              </Grid>

              {/* Expiration Date */}
              <Grid item xs={6}>
                <Typography variant="h6">Expiration Date</Typography>
                <Typography>{new Date(productInfo.expiration_date).toLocaleDateString()}</Typography>
              </Grid>

              {/* Added Date */}
              <Grid item xs={6}>
                <Typography variant="h6">Added Date</Typography>
                <Typography>{new Date(productInfo.added_date).toLocaleDateString()}</Typography>
              </Grid>
            </Grid>
          )}

         <Grid container spacing={2} sx={{ mt: 2 }}>
            <Grid item xs={6}>
              <TextField
                InputProps={{
                  inputProps: { min: 0 }
                }}
                margin="dense"
                label="Quantity"
                type="number"
                fullWidth
                value={productQty}
                onChange={handleQtyChange}
                disabled={scanSuccess}
                inputRef={qtyInputRef}
                
              />
            </Grid>

            {/* Total Amount Card */}
            <Grid item xs={6}>
              <Card sx={{ display: "flex", flexDirection: "column", justifyContent: "center", height: "100%" }}>
                <CardContent>
                  <Typography variant="h6" gutterBottom>
                    Total Amount
                  </Typography>
                  <Typography variant="h5" color="primary">
                    ETB {calculateTotalAmount()}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </DialogContent>
       
        <DialogActions>
          <Button onClick={handleDialogClose} color="secondary">
            Cancel
          </Button>
          <Button
        color="primary"
        disabled={true}
        type="submit" 
      >
        Confirm
      </Button>
        </DialogActions>
      </Dialog>
</Box>
    </BarcodeScannerContext.Provider>
  );
};

export { BarcodeScannerContext, BarcodeScannerProvider };
