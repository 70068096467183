import React, { useEffect, useState } from "react";
import Dashboard from "../../components/Dashboard/dashboard";
import AgroEmployeeReportService from "../../../services/agro_employee_report.service.js";
import { useAuth } from "../../../Context/AuthContext.js";
import { format } from "date-fns";
// import { parseISO } from "date-fns";
// import { Link } from "react-router-dom";

function AgroEmployeeReport() {
  const { employee } = useAuth();
  const [employeereport, setEmployeeReport] = useState([]);
  const [totalReportRecords, setTotalReportRecords] = useState(0);
  const EmployeeID = employee?.employee_id;

  useEffect(() => {
    const fetchReport = async () => {
      try {
        const response = await AgroEmployeeReportService.getReportByEmployeeID(
          EmployeeID
        );
        if (response) {
          if (response.status === "success") {
            setEmployeeReport(response.data);
            setTotalReportRecords(response.data.length);
          } else {
            console.error(`Error fetching employee report: ${response.status}`);
          }
        } else {
          console.error("Empty response received from the server");
        }
      } catch (error) {
        console.error("Error fetching employee report:", error.message);
      }
    };

    fetchReport();
  }, [EmployeeID]);

  return (
    <Dashboard>
      <h2 className="text-info text-start m-3">Important Reports!</h2>
      <div className="pt-4 px-4 text-start mb-3">
        <div className="row g-4">
          <div className="col-sm-6 col-xl-3 ">
            <div className="text-white bg-secondary revenue-div  rounded d-flex align-items-center justify-content-between p-4">
              <div className="ms-3">
                <p className="mb-2">Your Report Records:</p>
                <h6 className="mb-0">{totalReportRecords}</h6>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Conditional rendering based on totalReportRecords */}
      {totalReportRecords === 0 ? (
        <div className="card text-white bg-success mb-3">
          <h5 className="card-header ">
            Congratulations! All sales data has been validated and verified.
          </h5>
          <div className="card-body text-start">
            <h5 className="card-title mb-4">
              Dear {employee?.employee_first_name}
            </h5>
            <p className="card-text">
              We are pleased to inform you that our system has successfully
              recorded your daily sales data. Your diligence in ensuring
              accurate data entry is greatly appreciated and contributes
              significantly to the smooth functioning of our operations.
            </p>
            <p className="card-text">
              Thank you for your commitment to maintaining high standards of
              performance within our team.
            </p>
            <h6 className="card-title mb-4">Regards,</h6>
            <h6 className="card-title mb-4">AOSS Center Software</h6>
          </div>
        </div>
      ) : (
        employeereport.map((report, index) => {
          const reportDate = new Date(report.report_date);
          reportDate.setDate(reportDate.getDate() - 1); // Deduct one day

          return (
            <div
              key={index}
              className="card mb-3 bg-custom-d- border border-secondary"
            >
              <h4 className="card-header text-start text-danger border-secondary">
                {report.report_name} [{format(reportDate, "MM/dd/yyyy")}]
              </h4>
              <div className="card-body text-start text-light">
                <h5 className="card-title mb-4">
                  Dear {report.employee_name},
                </h5>
                <p className="card-text">{report.report_description}</p>
                <p className="card-text">
                  Please be aware that accurate data entry is crucial for our
                  operations and failure to comply affects the entire team's
                  performance.
                </p>
                <p className="card-text">
                  If this occurs again on two future occasions, the report will
                  be escalated to the manager, potentially resulting in
                  disciplinary action.
                </p>
                <p className="card-text">
                  Immediate compliance is necessary to avoid further
                  consequences.
                </p>
                <h6 className="card-title mb-4">Regards,</h6>
                <h6 className="card-title mb-4">{report.reported_by}</h6>
              </div>
            </div>
          );
        })
      )}
    </Dashboard>
  );
}

export default AgroEmployeeReport;
