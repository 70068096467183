import React, { useEffect, useState } from "react";
import "./Profile.css";
import { Link } from "react-router-dom";
import Dashboard from "../../../components/Dashboard/dashboard";
import { useAuth } from "../../../../Context/AuthContext";
import EmployeeProfileUpload from "../../../components/upload/EmployeeProfileUpload";
import EmployeeProfileView from "../../../components/upload/EmployeeProfileView";
import ChangeProfile from "../../../components/upload/ChangeProfile";
import Activity from "../Activity/Activity";
import Music from "../../../components/Widgets/Music";
import Weather from "../../../components/Widgets/weather";

const Profile = () => {
  const [displayComponent, setDisplayComponent] = useState("Profile");

  const { employee } = useAuth();
  const handleNavClick = (componentName) => {
    setDisplayComponent(componentName);
  };
  return (
    <>
      <Dashboard>
        <div className="card text-start pll bg-custom-d- text-white border-light">
          <div className="card-header border-dark mb-2 ovr-6 ">
            <ul className="nav nav-tabs card-header-tabs">
              <li className="nav-item">
                <Link
                  className={`nav-link ${
                    displayComponent === "Profile"
                      ? "bg-custom-d- text-white border-bottom-0"
                      : ""
                  } active ms-2`}
                  onClick={() => handleNavClick("Profile")}
                >
                  Profile
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className={`nav-link ${
                    displayComponent === "Activity"
                      ? "bg-custom-d- text-white border-bottom-0"
                      : ""
                  } active ms-2`}
                  onClick={() => handleNavClick("Activity")}
                >
                  My Activity 📈
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className={`nav-link ${
                    displayComponent === "Change Profile"
                      ? "bg-custom-d- text-white border-bottom-0"
                      : ""
                  } active ms-2`}
                  onClick={() => handleNavClick("Change Profile")}
                >
                  Change Profile ✨
                </Link>
              </li>{" "}
              <li className="nav-item">
                <Link
                  className={`nav-link ${
                    displayComponent === "Calendar"
                      ? "bg-custom-d- text-white border-bottom-0"
                      : ""
                  } active ms-2`}
                  onClick={() => handleNavClick("Calendar")}
                >
                  Calendar 🗓️
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className={`nav-link ${
                    displayComponent === "Weather"
                      ? "bg-custom-d- text-white border-bottom-0"
                      : ""
                  } active ms-2`}
                  onClick={() => handleNavClick("Weather")}
                >
                  Waether 🌥️
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className={`nav-link ${
                    displayComponent === "Music"
                      ? "bg-custom-d- text-white border-bottom-0"
                      : ""
                  } active ms-2`}
                  onClick={() => handleNavClick("Music")}
                >
                  Music 🎧
                </Link>
              </li>
            </ul>
          </div>
          {displayComponent === "Profile" && (
            <>
              <div className="row">
                <div className="col-md-4">
                  <div className="profile-img">
                    <EmployeeProfileView
                      picstyle={{
                        borderRadius: "50%",
                        width: "255px",
                        height: "255px",
                      }}
                    />
                  </div>
                </div>

                <div className="col-md-6 col-sm-12 mt-5">
                  <div className="profile-head">
                    <h5>
                      {employee?.employee_first_name}{" "}
                      {employee?.employee_last_name}
                    </h5>
                    <h6>{employee?.field_of_study}</h6>
                    <p className="">{employee?.employee_email}</p>
                    <ul className="nav nav-tabs" id="myTab" role="tablist">
                      <li className="nav-item">
                        <Link
                          className="nav-link bg-danger text-white me-2"
                          id="home-tab"
                        >
                          About me
                        </Link>
                      </li>
                      <li className="nav-item display-block">
                        <Link
                          to="/dashboard/help-request"
                          className="btn btn-sm btn-outline-success text-white ms-3"
                        >
                          Contact Support
                        </Link>
                      </li>{" "}
                      <li className="nav-item  dsyes d-lg-none">
                        <Link
                          onClick={() => handleNavClick("Change Profile")}
                          className="btn btn-sm btn-outline-info text-white ms-3"
                        >
                          Edit Profile
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-2 dsmnone">
                  <button
                    onClick={() => handleNavClick("Change Profile")}
                    className="btn btn-lg btn-outline-info text-white"
                  >
                    Edit Profile
                  </button>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4"></div>

                <div className="col-md-8">
                  <div className="tab-content profile-tab" id="myTabContent">
                    <div
                      className="tab-pane fade show active"
                      id="home"
                      role="tabpanel"
                      aria-labelledby="home-tab"
                    >
                      <div className="row">
                        <div className="col-md-6">
                          <label>Employee Id</label>
                        </div>
                        <div className="col-md-6">
                          <p>Not an Admin</p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <label>Name</label>
                        </div>

                        <div className="col-md-6">
                          <p> {employee?.employee_first_name} </p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <label>Email</label>
                        </div>
                        <div className="col-md-6">
                          <p>{employee?.employee_email}</p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <label>Phone</label>
                        </div>
                        <div className="col-md-6">
                          <p>{employee?.employee_phone}</p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <label>Profession</label>
                        </div>
                        <div className="col-md-6">
                          <p>{employee?.field_of_study}</p>
                        </div>
                      </div>{" "}
                      <div className="row">
                        <div className="col-md-6">
                          <label>Working Branch</label>
                        </div>
                        <div className="col-md-6">
                          <p>
                            {employee?.employee_branch} Agricultural One Stop
                            Shop center
                          </p>
                        </div>
                      </div>{" "}
                      <div className="row">
                        <div className="col-md-6">
                          <label>Employment Status</label>
                        </div>
                        <div className="col-md-6">
                          <p className="active-status">Active</p>
                        </div>
                      </div>{" "}
                      <div className="row">
                        <div className="col-md-6">
                          <label>Privileges</label>
                        </div>
                        <div className="col-md-6">
                          <p>{employee?.employee_privilege} Only</p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <label>Salary</label>
                        </div>
                        <div className="col-md-6">
                          <p>Birr {employee?.employee_salary} per/month</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}

          <div>
            {displayComponent === "Activity" && <Activity />}
            {displayComponent === "Change Profile" && <ChangeProfile />}
            {displayComponent === "Music" && <Music />}
            {displayComponent === "Weather" && <Weather />}
          </div>
        </div>
      </Dashboard>
    </>
  );
};

export default Profile;
