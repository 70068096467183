import React, { useState } from "react";
import Dashboard from "../Dashboard/dashboard";
import MonetizationOnOutlinedIcon from "@mui/icons-material/MonetizationOnOutlined";
import LeaderboardIcon from "@mui/icons-material/Leaderboard";
import PieChartOutlinedIcon from "@mui/icons-material/PieChartOutlined";
import StackedLineChartIcon from "@mui/icons-material/StackedLineChart";
import EditStoreAvailableProducts from "./EditStoreAvailableProducts";
import CloudSyncIcon from "@mui/icons-material/CloudSync";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditNoteIcon from "@mui/icons-material/EditNote";
import SyncIcon from "@mui/icons-material/Sync";
import ReplyIcon from "@mui/icons-material/Reply";
import { formatDistanceToNow } from "date-fns";
import { parseISO } from "date-fns";
import { format } from "date-fns";
import { Link } from "react-router-dom";
import {
  calculateCurrentMonthRevenue,
  calculateTodayRevenue,
  calculateTotalProductQuantity,
  calculateTotalRevenue,
} from "../Functions/CalculateFunctions";
import FilterListCard from "./FilterListCard";
import AddIcon from "@mui/icons-material/Add";
import AgroSoldProductTypePieChart from "../Charts/PieCharts/ProductTypePieChart";
import WeeklyproductqtyLineChart from "../Charts/SingleLineCharts/WeeklyproductqtyLineChart";
import { Chip, IconButton, Tooltip } from "@mui/material";
function StoreAvailableProducts({
  products,
  successMessage,
  Error,
  handleDeleteProduct,
  isLoading,
  APIError,
  handleEditsubmit,
  type,
  service,
  handleFetchData,
}) {
  const [showAll, setShowAll] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [productTypeFilter, setProductTypeFilter] = useState("");
  const [productUnitFilter, setProductUnitFilter] = useState("");
  const [branchFilter, setBranchFilter] = useState("");
  const [showFilterCard, setShowFilterCard] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [showEXPDates, setShowEXPDates] = useState(false);
  const [showDates, setShowDates] = useState(false);
  const [showUsage, setShowUsage] = useState(false);
  const [showFullNames, setshowFullNames] = useState(false);
  const handleShowFullNames = () => {
    setshowFullNames(!showFullNames);
  };
  const handleShowDates = () => {
    setShowDates(!showDates);
  };

  const handleShowUsage = () => {
    setShowUsage(!showUsage);
  };

  const handleShowEXPDates = () => {
    setShowEXPDates(!showEXPDates);
  };
  const handleToggleFilterCard = () => {
    setShowFilterCard(!showFilterCard);
  };
  const [editingProduct, setEditingProduct] = useState(null);
  const handleEdit = (products) => {
    setEditingProduct(products);
  };
  const handleCancelEdit = () => {
    setEditingProduct(null);
  };
  const handleFilterByProductType = (selectedOption) => {
    // Check if selectedOption is not null before accessing its value
    if (selectedOption && selectedOption.value) {
      setProductTypeFilter(selectedOption.value);
    } else {
      setProductTypeFilter("");
    }
  };

  const handleFilterByProductUnit = (selectedOption) => {
    // Check if selectedOption is not null before accessing its value
    if (selectedOption && selectedOption.value) {
      setProductUnitFilter(selectedOption.value);
    } else {
      // If selectedOption is null or doesn't have a value, clear the filter
      setProductUnitFilter("");
    }
  };
  const handleSetAdjustmentId = (ProductId) => {
    localStorage.setItem("adjustment", ProductId);
  };
  const handleFilterByBranch = (e) => {
    setBranchFilter(e.target.value);
  };

  const handleSetStartDate = (date) => {
    setStartDate(date);
  };

  const handleSetEndDate = (date) => {
    setEndDate(date);
  };
  const filteredproduct = showAll
    ? products
    : products
        .filter((products) => {
          const fieldsToSearch = [
            "product_id",
            "product_name",
            "product_unit_price",
          ];

          const addedDate = new Date(products.added_date);

          return (
            (!startDate || addedDate >= startDate) &&
            (!endDate || addedDate <= endDate) &&
            fieldsToSearch.some((field) =>
              String(products[field])
                .toLowerCase()
                .includes(searchInput.toLowerCase())
            ) &&
            (productTypeFilter === "" ||
              products.product_type.toLowerCase() ===
                productTypeFilter.toLowerCase()) &&
            (productUnitFilter === "" ||
              products.product_unit.toLowerCase() ===
                productUnitFilter.toLowerCase()) &&
            (branchFilter === "" ||
              products.branch.toLowerCase() === branchFilter.toLowerCase())
          );
        })
        .slice(0, 80);
  const clearFilters = () => {
    setBranchFilter("");
    setProductUnitFilter("");
    setProductTypeFilter("");
    setStartDate(null);
    setEndDate(null);
    setshowFullNames(false);
    setShowUsage(false);
    setShowEXPDates(false);
    setShowDates(false);
  };
  // Calculate today's revenue for the filtered products
  const todayRevenue = calculateTodayRevenue(
    filteredproduct,
    "added_date",
    "product_total_price"
  );

  // Calculate total revenue for the filtered products
  const totalRevenue = calculateTotalRevenue(
    filteredproduct,
    "product_total_price"
  );

  // Calculate current month's revenue for the filtered products
  const currentMonthRevenue = calculateCurrentMonthRevenue(
    filteredproduct,
    "added_date",
    "product_total_price"
  );

  // Calculate total quantity for the filtered products
  const totalProductQuantity = calculateTotalProductQuantity(
    filteredproduct,
    "product_quantity"
  );
  const handleShowAll = () => {
    setShowAll(!showAll);
  };

  const handleSearch = (e) => {
    setSearchInput(e.target.value);
  };
  return (
    <Dashboard>
      {editingProduct ? (
        <EditStoreAvailableProducts
          service={service}
          ProductId={editingProduct.product_id}
          onCancel={handleCancelEdit}
          editingProduct={editingProduct}
          products={products}
          APIError={APIError}
          handleEdit={handleEditsubmit}
          successMessage={successMessage}
        />
      ) : (
        <>
          <div className="container-fluid pt-4 px-4">
            {Error && <div className="alert alert-danger">{Error}</div>}

            {successMessage && (
              <div className="alert alert-success">{successMessage}</div>
            )}
            <div className="row g-4 text-white">
              <div className="col-sm-6 col-xl-3">
                <div className="ovr-1 rounded d-flex align-items-center justify-content-between p-4">
                  <i>
                    <StackedLineChartIcon className="newsalesicon" />
                  </i>
                  <div className="ms-3">
                    <p className="mb-2">Today Added</p>
                    <h6 className="mb-0">ETB {todayRevenue.toFixed(2)}</h6>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-xl-3">
                <div className="ovr-3 rounded d-flex align-items-center justify-content-between p-4">
                  <i>
                    <MonetizationOnOutlinedIcon className="newsalesicon" />
                  </i>
                  <div className="ms-3">
                    <p className="mb-2">Month's Added</p>
                    <h6 className="mb-0">
                      ETB {currentMonthRevenue.toFixed(2)}
                    </h6>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-xl-3">
                <div className="ovr-7 rounded d-flex align-items-center justify-content-between p-4">
                  <i>
                    <LeaderboardIcon className="newsalesicon" />
                  </i>
                  <div className="ms-3">
                    <p className="mb-2">Total Qty</p>
                    <h6 className="mb-0">QTY {totalProductQuantity}</h6>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-xl-3">
                <div className="ovr-9 rounded d-flex align-items-center justify-content-between p-4">
                  <i>
                    <PieChartOutlinedIcon className="newsalesicon" />
                  </i>
                  <div className="ms-3">
                    <p className="mb-2">Total Balance</p>
                    <h6 className="mb-0">ETB {totalRevenue.toFixed(2)}</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container-fluid  pt-4 px-4">
            <div className="row g-4">
              <div className="col-sm-12 col-xl-6">
                <div className="bg-black stprechart text-center rounded p-4">
                  <div className="d-flex align-items-center justify-content-between mb-4">
                    <h6 className="mb-0 text-warning">Weekly Added Product</h6>
                    <Link className="btn-sm btn btn-outline-danger">
                      Show All
                    </Link>
                  </div>
                  <WeeklyproductqtyLineChart data={filteredproduct} />
                </div>
              </div>
              <div className="col-sm-12 col-xl-6">
                <div className="bgsecondary stprechart text-center rounded p-2">
                  <div className="d-flex align-items-center justify-content-between ">
                    <h6 className="mb-0 text-warning">Product Type</h6>
                    <Link className="btn-sm btn btn-outline-danger ">
                      Show All
                    </Link>
                  </div>
                  <AgroSoldProductTypePieChart products={filteredproduct} />
                </div>
              </div>
            </div>
          </div>

          <div className="container-fluid pt-4 px-4 mb-5">
            <div className="store-table-container text-center rounded p-4">
              <div className="d-lg-flex d-md-flex d-sm-inline-block d-sm-block align-items-center justify-content-between mb-4">
                <div className="form-floating  ">
                  <input
                    type="search"
                    className="form-control"
                    id="floatingInput"
                    placeholder="Search Products"
                    value={searchInput}
                    onChange={handleSearch}
                  />
                  <label htmlFor="floatingInput">Search Products</label>
                </div>

                <FilterListCard
                  showFilterCard={showFilterCard}
                  handleToggleFilterCard={handleToggleFilterCard}
                  handleFilterByBranch={handleFilterByBranch}
                  handleFilterByProductUnit={handleFilterByProductUnit}
                  handleFilterByProductType={handleFilterByProductType}
                  branchFilter={branchFilter}
                  productUnitFilter={productUnitFilter}
                  productTypeFilter={productTypeFilter}
                  handleSetStartDate={handleSetStartDate}
                  handleSetEndDate={handleSetEndDate}
                />

                <Link
                  className="xpm btn-sm btn btn-outline-danger"
                  onClick={handleShowAll}
                >
                  {showAll ? "Show Less" : "Show All"}
                </Link>
              </div>
              <div className="table-responsive">
                <div className="d-flex justify-content-between align-items-center mt-0">
                  <div className="d-flex align-items-center">
                    <h6 className="text-info text-start mb-0 me-2">
                      Recent Added
                    </h6>
                    <button
                      className="btn btn-outline-light btn-sm me-2"
                      onClick={handleShowDates}
                    >
                      {showDates ? "Hide Added Date" : "Show Added Date"}
                    </button>{" "}
                    <button
                      className="btn btn-outline-info btn-sm me-2"
                      onClick={handleShowEXPDates}
                    >
                      {showEXPDates
                        ? "Hide Expiration Date"
                        : "Show Expiration Date"}
                    </button>{" "}
                    <button
                      className="btn btn-outline-warning btn-sm me-2"
                      onClick={handleToggleFilterCard}
                    >
                      Filters
                    </button>{" "}
                    <button
                      className="btn-ccgray text-white btn-sm me-2"
                      onClick={handleShowFullNames}
                    >
                      {showFullNames ? "Hide Full Names" : "Show Full Names"}
                    </button>
                    <button
                      className="btn btn-outline-danger btn-sm me-2"
                      onClick={clearFilters}
                    >
                      Clear Filters
                    </button>
                    {isLoading ? (
                      <SyncIcon className="fetchiconOnloading" />
                    ) : (
                      <CloudSyncIcon
                        onClick={handleFetchData}
                        className="refetchdataicon"
                      />
                    )}
                  </div>
                  {type !== "clone" ? (
                    <Link
                      className="btn btn-outline-success  btn-sm me-2"
                      to={
                        service === "vet"
                          ? `/dashboard/store/vet/add-product`
                          : `/dashboard/store/agro/add-product`
                      }
                    >
                      <AddIcon className="" />
                    </Link>
                  ) : (
                    ""
                  )}
                </div>

                <table className="table text-start align-middle table-striped table-hover mt-1 table-dark mb-0">
                  <thead>
                    <tr className="text-white">
                      <th scope="col">
                        <input className="form-check-input" type="checkbox" />
                      </th>
                      <th scope="col">ID</th>
                      <th scope="col">Name</th>
                      <th scope="col">Type</th>
                      <th scope="col">Unit</th>
                      <th scope="col">QTY</th>
                      <th scope="col">Price</th>
                      <th scope="col">Total</th>
                      <th scope="col">Branch</th>

                      <th scope="col">EXP At</th>
                      <th scope="col">Added Date</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {!filteredproduct.length && isLoading ? (
                      <tr>
                        <td colSpan="19" className="text-center">
                          <span className="loader"></span>
                        </td>
                      </tr>
                    ) : (
                      filteredproduct.map((products) => (
                        <tr key={products.product_id}>
                          <td>
                            <input
                              className="form-check-input bg-custom-d- "
                              type="checkbox"
                            />
                          </td>
                          <th scope="row">{products.product_id}</th>
                          <td className={`${showFullNames ? "" : "clip-text"}`}>
                            {products.product_name}
                          </td>
                          <td>{products.product_type}</td>
                          <td>{products.product_unit}</td>
                          <td>{products.product_quantity}</td>
                          <td>{products.product_unit_price}</td>
                          <td>{products.product_total_price}</td>
                          <td>{products.branch}</td>

                          {showEXPDates ? (
                            <td>
                              <Chip label={format(
                                  parseISO(products.expiration_date),
                                  "MM/dd/yyyy"
                                )} sx={{bg:"secondary", color: "white"}} variant="outlined" />
                            </td>
                          ) : (
                            <td>
                              <Chip label={formatDistanceToNow(
                                  parseISO(products.expiration_date),
                                  { addSuffix: true }
                                )} sx={{bg:"secondary", color: "white"}} variant="outlined" />
                            </td>
                          )}
                          {showDates ? (
                            <td>
                              <Chip label={format(
                                  parseISO(products.added_date),
                                  "MM/dd/yyyy HH:mm:ss"
                                )} sx={{bg:"info", color: "white"}} variant="outlined" />
                            </td>
                          ) : (
                            <td>
                              <Chip label=  {formatDistanceToNow(
                                  parseISO(products.added_date),
                                  {
                                    addSuffix: true,
                                  }
                                )} sx={{backgroundColor:"black", color: "white"}}  />
                            </td>
                          )}
                          <td className="d-flex">
                            {/* <Link
                              className="btn btn-sm btn-success  me-2"
                              onClick={() => handleEdit(products)}
                            >
                              Edit
                            </Link> */}
                            {/* <Link
                              className="btn-sm btn btn-danger me-3"
                              onClick={() =>
                                handleDeleteProduct(products.product_id)
                              }
                            >
                              Delete
                            </Link> */}
                            {/* <Link
                              className="sharecon rounded-1 "
                              onClick={() =>
                                handleSetAdjustmentId(products.product_id)
                              }
                              to="/dashboard/help-request"
                            >
                              <ReplyIcon className="Sicon" />
                            </Link> */}
                            <Tooltip title={"Edit Product"}>
                              <IconButton
                                aria-label="edit"
                                sx={{ color: "white" }}
                                onClick={() => handleEdit(products)}
                              >
                                <EditNoteIcon />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title={"Delete Product"}>
                              <IconButton
                                aria-label="delete"
                                sx={{ color: "red" }}
                                onClick={() =>
                                  handleDeleteProduct(products.product_id)
                                }
                              >
                                <DeleteOutlineIcon />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title={"Replay Product"}>
                              <Link to="/dashboard/help-request">
                                <IconButton
                                  aria-label="delete"
                                  sx={{ color: "red" }}
                                  onClick={() =>
                                    handleSetAdjustmentId(products.product_id)
                                  }
                                >
                                  <ReplyIcon className="Sicon" />
                                </IconButton>
                              </Link>
                            </Tooltip>
                          </td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </>
      )}
    </Dashboard>
  );
}

export default StoreAvailableProducts;
