import React, { useState, useEffect } from "react";
import Dashboard from "../Dashboard/dashboard";
import { Link } from "react-router-dom";
import { useAuth } from "../../../Context/AuthContext";
import { formatDistanceToNow } from "date-fns";
import { parseISO } from "date-fns";
import { format } from "date-fns";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import EditPayment from "./EditPayment";
const PettyPaymentComponent = ({
  balanceData,
  handleAddBalance,
  Payments,
  handleAddPayment,
  Error,
  setSuccessMessage,
  SuccessMessage,
  setError,
  showDates,
  handleShowDates,
  TotalPayments,
  DELETEPayment,
  handleEditPayment,
  isLoading,
}) => {
  const [Deposit, setDeposit] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [EditingPaymentData, setIsEditingPayment] = useState(null);
  const { employee } = useAuth();
  const [formData, setFormData] = useState({
    amount: "",
    purpose: "",
    recipient: "",
    branch: "",
    balance_id: "",
  });

  useEffect(() => {
    if (balanceData.length > 0) {
      setFormData((prevData) => ({
        ...prevData,
        balance_id: balanceData[0].balance_id,
      }));
    }
  }, [balanceData]);

  useEffect(() => {
    setFormData((prevData) => ({
      ...prevData,
      branch: employee?.employee_branch || "",
    }));
  }, [employee?.employee_branch]);
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };
  const [Newbalance, setNewbalance] = useState(null);
  const handleDeposit = (balance) => {
    setNewbalance(balance);
    setDeposit(!Deposit);
  };

  const [updatedBalanceData, setupdatedBalanceData] = useState({
    amount: "",
    ...Newbalance,
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setupdatedBalanceData((prevData) => ({ ...prevData, [name]: value }));
  };
  const handleSearch = (e) => {
    setSearchInput(e.target.value);
  };

  const FilteredPayments = Payments
    ? Payments.filter((Payments) => {
        const fieldsToSearch = [
          "payment_id",
          "amount",
          "purpose",
          "recipient",
          "branch",
          "payment_date",
        ];
        return fieldsToSearch.some((field) =>
          String(Payments[field])
            .toLowerCase()
            .includes(searchInput.toLowerCase())
        );
      })
    : [];

  const AddPayment = async (e) => {
    e.preventDefault();
    setIsButtonDisabled(true);
    try {
    const currentBalance = parseFloat(balanceData[0].amount);
      const paymentAmount = parseFloat(formData.amount);
      if (paymentAmount > currentBalance) {
        setError("Your current balance is inadequate.");
        return
      }
      const response = await handleAddPayment(formData);
      if (response.success) {
        setSuccessMessage(response.message);
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      } else {
        setError(response.error);
      }
    } catch (error) {
      setError(error.message || error.toString());
    } finally {
      setIsButtonDisabled(false);
    }
  };
  const totalAmount = FilteredPayments.reduce(
    (total, payment) => total + parseFloat(payment.amount),
    0
  );
  const handleEdit = (payment) => {
    setIsEditingPayment(payment);
  };
  const handleCancelEdit = () => {
    setIsEditingPayment(null);
  };
  return (
    <Dashboard>
      <div className="">Welcome</div>
      <div className="container mt-2">
        <div className="col-md-12 mb-5">
          <div className="gradient-border-pseudo p-4">
            {balanceData.map((balance, index) => (
              <div key={index} className="card-body">
                <h3>ETB {balance.amount}</h3>
                <p>(Currently available Balance: ETB {balance.amount})</p>
                <div className="d-flex justify-content-between align-items-center">
                  <div>
                    <span className="badge bg-warning me-2">{balance.branch}</span>
                    <span className="dif">{balance.amount}</span>
                  </div>
                  <div>
                    <Link
                      className="btn btn-md btn-outline-light "
                      onClick={() => handleDeposit(balance)}
                    >
                      Deposit
                    </Link>
                    {Deposit ? (
                      <>
                        <form
                          onSubmit={(event) =>
                            handleAddBalance(
                              event,
                              Newbalance.balance_id,
                              updatedBalanceData
                            )
                          }
                        >
                          <input
                            type="number"
                            className="form-control mt-2"
                            id="paymentInput0"
                            placeholder="Amount"
                            name="amount"
                            value={updatedBalanceData.amount}
                            onChange={handleInputChange}
                          />
                          <button
                            type="submit"
                            className="btn btn-primary mt-2"
                          >
                            Deposit ETB {updatedBalanceData.amount}
                          </button>
                        </form>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div>
                    <span className="badge bg-secondary me-2">On drafts</span>
                    <span className="dif">{balance.amount}</span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        {Error && (
          <div
            className="alert alert-danger text-center d-flex align-items-center justify-content-center"
            role="alert"
          >
            <HighlightOffIcon className="me-3 errricon" />
            <div className="text-center erri">{Error}</div>
          </div>
        )}
        {SuccessMessage && (
          <div
            className="alert alert-success d-flex align-items-center "
            role="alert"
          >
            <CheckCircleIcon className="bi flex-shrink-0 me-2" />
            <div>{SuccessMessage}</div>
          </div>
        )}
        <div className="row">
          <div className="col-md-12 col-sm-12  mb-3">
            <div className="card  bg-custom-d- text-white">
              <div className="card-body ">
                <div className="d-flex justify-content-between align-items-center ">
                  <h5 className="card-title text-start mb-4">Payments</h5>
                  <button
                    className="btn btn-outline-light btn-sm me-2"
                    onClick={handleShowDates}
                  >
                    {showDates ? "Hide Payment Date" : "Show Payment Date"}
                  </button>
                </div>
                <h6 className="card-title text-start"> Search Payments</h6>
                <div className="form-floating col-md-3 mb-2">
                  <input
                    type="search"
                    className="form-control"
                    id="floatingInput"
                    placeholder="Search Payments"
                    value={searchInput}
                    onChange={handleSearch}
                  />
                  <label htmlFor="floatingInput text-gray">
                    Search Payments
                  </label>
                </div>
                <div className="table-responsive">
                  <table className="table  table-dark  hover table-striped ">
                    <thead>
                      <tr>
                        <th scope="col">Payment Id</th>
                        <th scope="col">Amount</th>
                        <th scope="col">Purpose</th>
                        <th scope="col">Recipient</th>
                        <th scope="col">Branch</th>
                        <th scope="col">Payment Date</th>
                        <th scope="col">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {!FilteredPayments.length && isLoading ? (
                        <tr>
                          <td colSpan="19" className="text-center">
                            <span className="loader"></span>
                          </td>
                        </tr>
                      ) : (
                        FilteredPayments.map((payment, index) => (
                          <tr key={index}>
                            <td>{payment.payment_id}</td>
                            <td>{payment.amount}</td>
                            <td>{payment.purpose}</td>
                            <td>{payment.recipient}</td>
                            <td>{payment.branch}</td>

                            {showDates ? (
                              <td>
                                <span className="sold_date-format">
                                  {format(
                                    parseISO(payment.payment_date),
                                    "MM/dd/yyyy HH:mm:ss"
                                  )}
                                </span>
                              </td>
                            ) : (
                              <td>
                                <span className="badge rounded-pill bg-light text-dark">
                                  {formatDistanceToNow(
                                    parseISO(payment.payment_date),
                                    {
                                      addSuffix: true,
                                    }
                                  )}
                                </span>
                              </td>
                            )}
                            <td>
                              <div className="justify-content-between ">
                                <Link
                                  className="btn btn-sm btn-outline-success me-2"
                                  onClick={() => handleEdit(payment)}
                                >
                                  Edit
                                </Link>
                                <Link
                                  className="btn btn-sm btn-outline-danger "
                                  onClick={() =>
                                    DELETEPayment(payment.payment_id)
                                  }
                                >
                                  Remove
                                </Link>
                              </div>
                            </td>
                          </tr>
                        ))
                      )}
                    </tbody>
                  </table>
                </div>
                <div className="justify-content-between d-flex ">
                  <p className="font-monospace ">
                    Total Payment: {TotalPayments}
                  </p>
                  <p className="font-monospace ">
                    Total Amount: {totalAmount.toFixed(2)}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="card  bg-custom-d- text-white">
              <div className="card-body ">
                <h5 className="card-title">Payments</h5>
                <div className="mb-3 text-start mt-3">
                  <h5 className="card-title">Add New Petty Cash Payment</h5>
                  <label htmlFor="paymentInput1" className="form-label">
                    Purpose
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="paymentInput1"
                    placeholder="Purpose"
                    name="purpose"
                    value={formData.purpose}
                    onChange={handleChange}
                    required
                  />{" "}
                  <label htmlFor="paymentInput2" className="form-label">
                    Recipient
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="paymentInput2"
                    placeholder="Recipient"
                    name="recipient"
                    value={formData.recipient}
                    onChange={handleChange}
                    required
                  />{" "}
                  <label htmlFor="paymentInput3" className="form-label">
                    Amount
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    id="paymentInput3"
                    placeholder="Amount"
                    name="amount"
                    value={formData.amount}
                    onChange={handleChange}
                    required
                  />
                </div>
                <button
                  type="submit"
                  onClick={AddPayment}
                  className="btn btn-primary"
                >
                  PAY {formData.amount}
                </button>
              </div>
            </div>
          </div>
          {EditingPaymentData && (
            <EditPayment
              handleEditPayment={handleEditPayment}
              onCancel={handleCancelEdit}
              EditingPaymentData={EditingPaymentData}
              PaymentId={EditingPaymentData.payment_id}
            />
          )}
        </div>
      </div>
    </Dashboard>
  );
};

export default PettyPaymentComponent;
