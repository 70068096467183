import React, { useEffect, useState } from "react";
import Center1 from "../../../assets/images/center12.png";
import Carousel from "react-bootstrap/Carousel";

import "bootstrap/dist/css/bootstrap.min.css";
function DarkVariantExample() {
  const [textIndex, setTextIndex] = useState(0);
  const [displayedText, setDisplayedText] = useState("");
  const textArray = [
    "እንኳን ወደ ቡኢ የግብርና ግብአትና አገልግሎት ማዕከል በደህና መጡ!",
    "በግብርና ላይ የሚታመን አጋርዎ!",
    "ጥራት ያላቸው ምርቶችና የባለሙያዎች ድጋፍ",
    "Welcome To Buee Agricultural One Stop Shop Center",
    "Your Trusted Partner in Agriculture",
    "Quality Products and Expert Support",
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setTextIndex((prevIndex) => (prevIndex + 1) % textArray.length);
      setDisplayedText("");
    }, 10000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const currentText = textArray[textIndex];
    let charIndex = 0;
    const typingInterval = setInterval(() => {
      setDisplayedText(currentText.slice(0, charIndex + 1));
      charIndex++;
      if (charIndex === currentText.length) {
        clearInterval(typingInterval);
      }
    }, 150); // Typing speed

    return () => clearInterval(typingInterval);
  }, [textIndex]);

  return (
    <div
      id="Home"
      className="black-blur"
      style={{ display: "block", width: "100%" }}
    >
      <Carousel data-bs-theme="light">
        <Carousel.Item interval={2900}>
          <img className="d-block w-100 fimg" src={Center1} />
          <Carousel.Caption className="heroh1s">
            <h1 className="heroh1s text-center d-sm-none d-md-none d-lg-block">
              {displayedText}
            </h1>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
      <h1 className="heroh1sMobile d-lg-none  text-center">
        Buee Agricultural One Stop Shop Center
      </h1>
    </div>
  );
}

export default DarkVariantExample;
