import React, { useState } from "react";
import employeeProfileService from "../../../services/employee_profile.service";

const EmployeeProfileUpload = () => {
  const employeeId = localStorage.getItem("EmployeeID");

  const [selectedFile, setSelectedFile] = useState(null);
  const [showSuccess, setShowSuccess] = useState(false);
  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleUpload = async () => {
    if (!selectedFile) {
      alert("Please select a file to upload.");
      return;
    }

    const formData = new FormData();
    formData.append("profilePicture", selectedFile);

    try {
      await employeeProfileService.uploadEmployeeProfilePicture(
        formData,
        employeeId
      );
      setShowSuccess(true);
      setTimeout(() => {
        setShowSuccess(false);
        window.location.reload(); // Reload the page after 4 seconds
      }, 4000);
      // Optionally, you can update the UI or state to reflect the uploaded picture
    } catch (error) {
      console.error("Error uploading profile picture:", error.message);
      alert("Failed to upload profile picture. Please try again later.");
    }
  };

  return (
    <>
      {showSuccess && (
        <div className="alert alert-success mt-3" role="alert">
          Profile picture uploaded successfully!
        </div>
      )}
      <div className="input-group col-sm-12 uploadpro mt-3">
        <input
          type="file"
          className="form-control bg-custom-d- text-white "
          id="inputGroupFile04"
          onChange={handleFileChange}
          aria-describedby="inputGroupFileAddon04"
          aria-label="Upload"
        />
        <button
          className="btn btn-primary"
          onClick={handleUpload}
          type="button"
          id="inputGroupFileAddon04"
        >
          Upload
        </button>
      </div>
    </>
  );
};

export default EmployeeProfileUpload;
