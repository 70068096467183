import React from "react";
import {
  calculateCurrentMonthRevenue,
  calculateTodayRevenue,
  calculateTotalProductQuantity,
  calculateTotalRevenue,
} from "../Functions/CalculateFunctions";
import { Link } from "react-router-dom";

function SalesCard({ data }) {
  // Calculate today's revenue for the filtered products
  const todayRevenue = calculateTodayRevenue(
    data,
    "sold_date",
    "product_total_price"
  );

  // Calculate total revenue for the filtered products
  const totalRevenue = calculateTotalRevenue(data, "product_total_price");

  // Calculate current month's revenue for the filtered products
  const currentMonthRevenue = calculateCurrentMonthRevenue(
    data,
    "sold_date",
    "product_total_price"
  );

  // Calculate total quantity for the filtered products
  const totalProductQuantity = calculateTotalProductQuantity(
    data,
    "product_quantity"
  );
  return (
    <div className="col-sm-12 col-md-6 col-xl-6 mb-4 mt-3">
      <div className="h-100 bg-custom-d- border border-light text-white  rounded p-4">
        <div className="d-flex align-items-center border-bottom border-gray pb-3 justify-content-between mb-2">
          <p className="mb-0">Sales Report</p>
          <Link className="text-danger ">Show All</Link>
        </div>
        <table className="table table-dark table-borderless table-striped-columns  align-middle  justify-content-center">
          <tbody>
            <tr className="align-middle">
              <td>
                <div className="d-flex text-start">
                  <h6 className="mb-0 ms-3">Today Revenue</h6>
                </div>
              </td>
              <td>
                <span className="custom-pill-yellow ">Today</span>
              </td>
              <td>
                {todayRevenue <= 3000 ? (
                  <span className="badge bg-primary">ETB {todayRevenue} </span>
                ) : (
                  <span className="badge rounded-pill bg-success">
                    ETB {todayRevenue}
                  </span>
                )}
              </td>
            </tr>
            <tr className="align-middle">
              <td>
                <div className="d-flex text-start">
                  <h6 className="mb-0 ms-3">Current Month Total Revenue</h6>
                </div>
              </td>
              <td>
                <span className="custom-pill-yellow ">Current Month</span>
              </td>
              <td>
                <span className="badge bg-success rounded-0 ms-1">
                  {currentMonthRevenue}
                </span>
              </td>
            </tr>
            <tr className="align-middle">
              <td>
                <div className="d-flex text-start">
                  <h6 className="mb-0 ms-3">Total Grand Revenue</h6>
                </div>
              </td>
              <td>
                <span className="custom-pill-yellow">All Time</span>
              </td>
              <td>
                <span className="badge bg-light text-dark ">
                  ETB:
                  <span className="badge bg-success rounded-0 ms-1">
                    {totalRevenue}
                  </span>
                </span>
              </td>
            </tr>
            <tr className="align-middle">
              <td>
                <div className="d-flex text-start">
                  <h6 className="mb-0 ms-3">Total Product Quantity</h6>
                </div>
              </td>
              <td>
                <span className="custom-pill-yellow ">All Time</span>
              </td>
              <td>
                <span className="badge bg-white text-black rounded-1 ms-1">
                  QTY {totalProductQuantity}
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default SalesCard;
