import React, { useState, useEffect } from "react";
import Dashboard from "../../../components/Dashboard/dashboard";
import MessageIcon from "@mui/icons-material/Message";
import PreviewIcon from "@mui/icons-material/Preview";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { Link } from "react-router-dom";
import {
  formatDistanceToNow,
  differenceInMinutes,
  differenceInHours,
  differenceInDays,
  differenceInWeeks,
  differenceInMonths,
  differenceInYears,
  parseISO,
} from "date-fns";
import { format } from "date-fns";
import helpService from "../../../../services/help.service";
import MarkChatReadIcon from "@mui/icons-material/MarkChatRead";
import HelpresponseService from "../../../../services/help_responce.service";

function EmployeeHelp() {
  const [helpRequests, setHelpRequests] = useState([]);
  const [helpResponses, setHelpResponses] = useState([]);
  const [showDates, setShowDates] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const EmployeeID = localStorage.getItem("EmployeeID");
    const [loadingHelpRequests, setLoadingHelpRequests] = useState(false);
  useEffect(() => {
    const fetchData = async () => {
      setLoadingHelpRequests(true); // Set loadingHelpRequests to true before fetching data
      try {
        const response = await helpService.getHelpByEmployeeId(EmployeeID);

        if (response && response.status === "success" && response.data) {
          const responseData = response.data;
          setHelpRequests(responseData);
        } else {
          console.error("No data returned from the server");
        }
      } catch (error) {
        console.error("Error fetching help requests:", error);
      } finally {
        setLoadingHelpRequests(false); // Set loadingHelpRequests to false after fetching data
      }
    };

    fetchData();
    const intervalId = setInterval(fetchData, 5000);

    // Cleanup function to clear interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    const fetchResponses = async () => {
      try {
        if (Array.isArray(helpRequests)) {
          const responses = await Promise.all(
            helpRequests.map(async (helpRequest) => {
              const response = await HelpresponseService.getResponsesByHelpId(
                helpRequest.help_id
              );
              return response.data;
            })
          );
          setHelpResponses(responses);
        } else {
          console.error("helpRequests is not an array:");
        }
      } catch (error) {
        console.error("Error fetching help responses:", error);
      }
    };

    const intervalId = setInterval(fetchResponses, 1000);
    return () => clearInterval(intervalId);
  }, [helpRequests]);

  const handleDeleteRequest = async (helpId) => {
    if (window.confirm("Are you sure you want to delete this Request?")) {
      try {
        const response = await helpService.deleteHelpRequest(helpId);

        if (response.ok) {
          setSuccessMessage("Request deleted successfully");
        } else {
          throw new Error(`Failed to delete Request: ${response.status}`);
        }
      } catch (error) {
        setErrorMessage("Error: " + error.message);
      }
    }
  };
  const handleDeleteResponse = async (responseId) => {
    if (window.confirm("Are you sure you want to delete this Response?")) {
      try {
        const response = await HelpresponseService.deleteResponse(responseId);

        if (response.ok) {
          setSuccessMessage("Response deleted successfully");
        } else {
          throw new Error(`Failed to delete response: ${response.status}`);
        }
      } catch (error) {
        setErrorMessage("Error: " + error.message);
      }
    }
  };

  const handleShowDates = () => {
    setShowDates(!showDates);
  };

  return (
    <Dashboard>
      <div className="card border-white mb-3 m-5 bg-custom-d-">
        {successMessage && (
          <div className="alert alert-success" role="alert">
            {successMessage}
          </div>
        )}
        {errorMessage && (
          <div className="alert alert-danger" role="alert">
            {errorMessage}
          </div>
        )}
        <div className="justify-content-end card-header border-white text-white bg-gradient d-flex justify-content-between">
          <div className="d-flex">
            <h4 className="me-2">Help Requests</h4>{" "}
            <button
              className="btn btn-sm btn-outline-info text-white me-2"
              onClick={handleShowDates}
            >
              {showDates ? "Hide Dates" : "Show Dates"}
            </button>
          </div>
          <Link
            to="/dashboard/help-request"
            className="btn btn-md btn-danger text-white"
          >
            New Request
          </Link>
        </div>
        <div className="mt-5 mb-5">
          {helpRequests.length === 0 && loadingHelpRequests ? (
            <div className="nonetable_loader"></div>
          ) : (
            <></>
          )}
        </div>
        <div className="card-body text-success">
          {helpRequests.length > 0 ? (
            helpRequests.map((helpRequest, index) => (
              <>
                <div
                  key={helpRequest.help_id}
                  className="card bg-custom-d- border-white m-5"
                >
                  <p className="card-text text-gray text-start m-2 text-decoration-underline ">
                    {helpResponses[index] ? (
                      <PreviewIcon className="text-white me-2" />
                    ) : (
                      <VisibilityOffIcon className="text-white me-2" />
                    )}
                    {showDates ? (
                      <span className="text-gray">
                        {format(
                          parseISO(helpRequest.added_date),
                          "MM/dd/yyyy HH:mm:ss"
                        )}
                      </span>
                    ) : (
                      <span className="text-gray">
                        {formatDistanceToNow(parseISO(helpRequest.added_date), {
                          addSuffix: true,
                        })}
                      </span>
                    )}
                  </p>{" "}
                  <div className="card-body d-flex ">
                    <h5 className="card-title text-start text-white">
                      <MessageIcon className="text-white MessageIC me-3" />
                    </h5>
                    <div className="d-block text-start">
                      <div className="bg-custom-d- text-white rounded h-100 p-2">
                        <dl className="row mb-0">
                          <dt className="col-sm-4">Employee Name:</dt>
                          <dd className="col-sm-8">
                            {helpRequest.employee_name}
                          </dd>
                          <dt className="col-sm-4">Topic:</dt>
                          <dd className="col-sm-8">{helpRequest.topic}</dd>
                          <dt className="col-sm-4">Description:</dt>
                          <dd className="col-sm-8">
                            {helpRequest.description}
                          </dd>
                          <dt className="col-sm-4 text-truncate">Reason:</dt>
                          <dd className="col-sm-8">{helpRequest.reason}</dd>
                          <dt className="col-sm-4">More info</dt>
                          <dd className="col-sm-8">
                            <dl className="row">
                              <dt className="col-sm-4">Adjustment Id:</dt>
                              <dd className="col-sm-8">
                                {!helpRequest.adjustment_id ? (
                                  <span className="badge badge-light bg-warning text-danger">
                                    ID Not Provided!
                                  </span>
                                ) : (
                                  <span className="badge badge-light bg-white text-black">
                                    {helpRequest.adjustment_id}
                                  </span>
                                )}
                              </dd>
                            </dl>
                          </dd>
                        </dl>
                      </div>
                    </div>
                  </div>
                  <div className="text-gray text-start mb-0 m-1">
                    <Link
                      className="col-sm-4 text-truncate text-danger "
                      onClick={() => handleDeleteRequest(helpRequest.help_id)}
                    >
                      Delete
                    </Link>
                  </div>
                  <hr className="text-white" />
                  {/* Render help response */}
                  {helpResponses[index] ? (
                    <>
                      <p className="col-sm-8 text-start text-gray">
                        {showDates ? (
                          <span className="">
                            {format(
                              parseISO(helpResponses[index].response_date),
                              "MM/dd/yyyy HH:mm:ss"
                            )}
                          </span>
                        ) : (
                          <span className="">
                            {formatDistanceToNow(
                              parseISO(helpResponses[index].response_date),
                              {
                                addSuffix: true,
                              }
                            )}
                          </span>
                        )}
                      </p>
                      <div className="card-body d-flex">
                        <h5 className="card-title text-start text-white">
                          <MarkChatReadIcon className="text-white MessageIC me-3" />
                        </h5>
                        <div className="d-block text-start">
                          <div className="bg-custom-d- text-white rounded h-100 p-2">
                            <dl className="row mb-0">
                              <dt className="col-sm-4">RESP:</dt>
                              <dd className="col-sm-8">
                                {helpResponses[index].responder_name}
                              </dd>
                              <dt className="col-sm-4">MSG:</dt>
                              <dd className="col-sm-8">
                                {helpResponses[index].response_message}
                              </dd>
                            </dl>
                          </div>
                        </div>
                      </div>
                      <div className="text-gray text-start mb-0 m-1">
                        <Link
                          className="col-sm-4 text-truncate text-danger "
                          onClick={() =>
                            handleDeleteResponse(
                              helpResponses[index].response_id
                            )
                          }
                        >
                          Delete
                        </Link>
                      </div>
                    </>
                  ) : (
                    <>
                      <h5 className="text-white">No responses available.</h5>
                      <p className="text-gray">
                        If you don't receive a response to your help request
                        within 3 days, please call +251924676177{" "}
                        <Link
                          to="https://natnaelhailu.com/"
                          className="text-success "
                          target="_blanck"
                        >
                          Natnael Hailu
                        </Link>
                      </p>
                    </>
                  )}
                  <hr className="text-white" />
                  <div className="m-1 d-block text-start">
                    {helpResponses[index] &&
                    helpResponses[index].response_date ? (
                      <p className="text-gray">
                        Response provided:
                        {differenceInYears(
                          parseISO(helpResponses[index].response_date),
                          parseISO(helpRequest.added_date)
                        ) > 0
                          ? ` ${differenceInYears(
                              parseISO(helpResponses[index].response_date),
                              parseISO(helpRequest.added_date)
                            )} years`
                          : differenceInMonths(
                              parseISO(helpResponses[index].response_date),
                              parseISO(helpRequest.added_date)
                            ) > 0
                          ? ` ${differenceInMonths(
                              parseISO(helpResponses[index].response_date),
                              parseISO(helpRequest.added_date)
                            )} months`
                          : differenceInWeeks(
                              parseISO(helpResponses[index].response_date),
                              parseISO(helpRequest.added_date)
                            ) > 0
                          ? ` ${differenceInWeeks(
                              parseISO(helpResponses[index].response_date),
                              parseISO(helpRequest.added_date)
                            )} weeks`
                          : differenceInDays(
                              parseISO(helpResponses[index].response_date),
                              parseISO(helpRequest.added_date)
                            ) > 0
                          ? ` ${differenceInDays(
                              parseISO(helpResponses[index].response_date),
                              parseISO(helpRequest.added_date)
                            )} days`
                          : differenceInHours(
                              parseISO(helpResponses[index].response_date),
                              parseISO(helpRequest.added_date)
                            ) > 0
                          ? ` ${differenceInHours(
                              parseISO(helpResponses[index].response_date),
                              parseISO(helpRequest.added_date)
                            )} hours`
                          : differenceInMinutes(
                              parseISO(helpResponses[index].response_date),
                              parseISO(helpRequest.added_date)
                            ) > 0
                          ? ` ${differenceInMinutes(
                              parseISO(helpResponses[index].response_date),
                              parseISO(helpRequest.added_date)
                            )} minutes`
                          : ` less than a minute`}{" "}
                        following the request by{" "}
                        {helpResponses[index].responder_name}.
                      </p>
                    ) : (
                      <p className="text-white">No response available yet.</p>
                    )}
                  </div>
                </div>
              </>
            ))
          ) : (
            <p>Sorry! No help requests found</p>
          )}
        </div>
        <div className="card-footer bg-gradient  text-warning ">
          © Buee Agricultural One Stop Shop Center, All Right Reserved.
        </div>
      </div>
    </Dashboard>
  );
}

export default EmployeeHelp;
