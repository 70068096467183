import React, { useEffect, useState } from "react";
import Dashboard from "../Dashboard/dashboard";

import { Link } from "react-router-dom";
import ProductTypePieChart from "../Charts/PieCharts/ProductTypePieChart";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import FolderIcon from "@mui/icons-material/Folder";
import * as XLSX from "xlsx";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import WeeklyReport from "./Weekly";
import ReportOverView from "./ReportOverView";
import ReportDoughnutChart from "../Charts/DoughnutCharts/ReportDoughnutChart";
import ReportBarchart from "../Charts/BarCharts/ReportBarchart";
import CustomerStatsCard from "../Customer/CustomerStatsCard";
import SalesCard from "./SalesCard";
function Report({ Customers, SalesReport, soldProducts }) {
  const [filteredReports, setFilteredReports] = useState({});
  const [showAll, setShowAll] = useState(false);

  useEffect(() => {
    const filteredData = SalesReport.reduce((acc, report) => {
      const dateParts = report.date.split(" ")[0].split("-");
      const year = parseInt(dateParts[0]);
      const month = parseInt(dateParts[1]);
      const day = parseInt(dateParts[2]);

      if (day === 23) {
        if (!acc[year]) {
          acc[year] = {};
        }
        if (!acc[year][month]) {
          acc[year][month] = [];
        }
        acc[year][month].push(report);
      }

      return acc;
    }, {});

    setFilteredReports(filteredData);
  }, [SalesReport]);

  const exportToExcel = (reports, year, month) => {
    const ws = XLSX.utils.json_to_sheet(reports);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, `Monthly_Report_${getMonthName(month)}_${year}.xlsx`);
  };

  const getMonthName = (month) => {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    return months[month - 1];
  };
  const toggleShowAll = () => {
    setShowAll(!showAll);
  };
  return (
    <Dashboard>
      <h1 className="rounded-3 mb-4 custom-pill-pink">
        {" "}
        <MonetizationOnIcon className=" ssic text-gold" />
        ales Report
      </h1>
      <ReportOverView SalesReport={SalesReport} />
      <div className="container-fluid  pt-4 px-4">
        <div className="row g-4">
          <div className="col-sm-12 col-xl-6 border-light ">
            <div className=" border  border-light  text-center rounded p-4 pb-4">
              <div className="d-flex align-items-center justify-content-between mb-4">
                <h6 className="mb-0 text-danger">All Product Type Sales</h6>
                <Link>Show All</Link>
              </div>
              <ProductTypePieChart products={SalesReport} />
            </div>
          </div>
          <div className="col-sm-12 col-xl-6">
            <div className=" border  border-primary text-center rounded p-4">
              <div className="d-flex align-items-center justify-content-between mb-4">
                <h6 className="mb-0 text-info">
                  Last 6 Month Report By Categories
                </h6>
                <Link>Show All</Link>
              </div>
              <ReportDoughnutChart data={SalesReport} />
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid  pt-4 px-4">
        <div className="row g-4">
          <div className="col-sm-12 col-xl-12">
            <div className=" brd-gradient text-center rounded p-4">
              <div className="d-flex align-items-center justify-content-between mb-4">
                <h6 className="mb-0 text-gray">Last Week Sales</h6>
                <Link>Show All</Link>
              </div>
              <ReportBarchart weeklyData={SalesReport} />
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid pt-4 px-4 ">
        <div className="row g-4">
          {Object.keys(filteredReports).map((year) => (
            <div key={year} className="col-sm-12 col-md-6 col-xl-6 mb-4 mt-3">
              <div className="h-100 bg-custom-d- border border-light text-white  rounded p-4">
                <div className="d-flex align-items-center border-bottom border-gray p justify-content-between mb-2">
                  <p className="mb-0">Monthly Reports - {year}</p>

                  <Link className="text-danger " onClick={toggleShowAll}>
                    Show All
                  </Link>
                </div>
                <table className="table table-dark table-borderless justify-content-center">
                  <tbody>
                    {Object.keys(filteredReports[year])
                      .slice(0, showAll ? filteredReports.length : 4)
                      .map((month) => (
                        <tr key={`${year}-${month}`}>
                          <td>
                            <div className="d-flex text-start">
                              <FolderIcon />
                              <h6 className="mb-0 ms-3">
                                Report {getMonthName(parseInt(month))}
                              </h6>
                            </div>
                          </td>
                          <td>
                            <span className="custom-pill-pink">
                              {getMonthName(parseInt(month))}{" "}
                            </span>
                          </td>
                          <td>
                            <Link
                              className="btn btn-primary btn-sm"
                              onClick={() =>
                                exportToExcel(
                                  filteredReports[year][month],
                                  year,
                                  month
                                )
                              }
                            >
                              <CloudDownloadIcon /> Download Excel
                            </Link>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          ))}
          <WeeklyReport SalesReport={SalesReport} />
        </div>
      </div>
      <div className="container-fluid pt-4 px-4 ">
        <div className="row g-4">
          <CustomerStatsCard data={Customers} type="custom" service/>
          <SalesCard data={soldProducts} />
        </div>
      </div>
    </Dashboard>
  );
}

export default Report;
