import React, { useEffect, useState } from "react";
import CreatableSelect from "react-select/creatable";
import Select from "react-select";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

function EditOrder({
  handleEditOrder,
  OrderId,
  onCancel,
  SuccessMessage,
  editingOrder,
  products,
  APIError,
}) {
  const [updatedOrderData, setupdatedOrderData] = useState({ ...editingOrder });

  const [serverError, setServerError] = useState("");

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setupdatedOrderData((prevData) => ({ ...prevData, [name]: value }));
  };

  function handleSelectChange(selectedOption, field) {
    setupdatedOrderData((prevData) => ({
      ...prevData,
      [field]: selectedOption ? selectedOption.value : null,
    }));
  }

  const handleCreateOption = (inputValue, field) => {
    const newOption = { value: inputValue, label: inputValue };
    setupdatedOrderData((prevData) => ({
      ...prevData,
      [field]: inputValue,
    }));
    return newOption;
  };

  const productOptions = products.map((agroproduct) => ({
    value: agroproduct.product_id,
    label: agroproduct.product_name,
  }));

  // Update state when editingOrder prop changes
  useEffect(() => {
    setupdatedOrderData({ ...editingOrder });
  }, [editingOrder]);
  return (
    <div className="col-12 col-sm-8 col-md-6 col-lg-5 col-xl-7 text-black text-start div-center">
      <div className="bg-custom-d- rounded p-4 p-sm-5 my-4 mx-3">
        <div className="d-flex align-items-center justify-content-between  w-100">
          <h3 className="text-white text-bg-gradient">Edit Order</h3>
          <div className="fs-6 text-danger" onClick={onCancel}>
            <HighlightOffIcon />
          </div>
        </div>
        {serverError && (
          <div className="alert alert-success text-dark " role="alert">
            {serverError}
          </div>
        )}
        {SuccessMessage && (
          <div className="alert alert-success text-dark " role="alert">
            {SuccessMessage}
          </div>
        )}

        {APIError && (
          <div className="alert-danger" role="alert">
            {APIError}
          </div>
        )}
        <form
          onSubmit={(event) =>
            handleEditOrder(event, OrderId, updatedOrderData)
          }
        >
          <div className="mb-3">
            <CreatableSelect
              isClearable
              onChange={(selectedOption) =>
                handleSelectChange(selectedOption, "product_id")
              }
              onCreateOption={(inputValue) =>
                handleCreateOption(inputValue, "product_id")
              }
              options={productOptions}
              value={
                updatedOrderData.product_id
                  ? {
                      value: updatedOrderData.product_id,
                      label: updatedOrderData.product_id,
                    }
                  : null
              }
              placeholder="Search for a product..."
            />
          </div>
          {/* product_quantity */}
          <div className="input-group mb-3">
            <span className="input-group-text">QTY</span>
            <input
              type="text"
              className="form-control p-3"
              id="floatingInput"
              placeholder="Product QTY"
              name="product_quantity"
              value={updatedOrderData.product_quantity}
              onChange={handleInputChange}
            />
          </div>

          {/* customer_post_id */}
          <div className="input-group mb-3">
            <span className="input-group-text">Customer Post ID</span>
            <input
              type="text"
              className="form-control p-3"
              id="floatingInput"
              placeholder="Product Unit Price"
              name="customer_post_id"
              value={updatedOrderData.customer_post_id}
              onChange={handleInputChange}
            />
          </div>

          {/* customer_id */}
          <div className="input-group mb-3">
            <span className="input-group-text">Customer ID</span>
            <input
              type="text"
              className="form-control p-3"
              id="floatingInput"
              placeholder="Product Unit Price"
              name="customer_id"
              value={updatedOrderData.customer_id}
              onChange={handleInputChange}
            />
          </div>

          <button type="submit" className="btn btn-lg pm w-100 mt-2 p-3">
            Update Order
          </button>
        </form>
      </div>
    </div>
  );
}

export default EditOrder;
